import OSS from "ali-oss";
import API from "@/request";

const CooOss = function(name, file, processCallback = () => {}) {
  return new Promise((resolve, reject) => {
    API.getStsToken().then(async result => {
        let stsInfo = result.data.data;
        let client = new OSS({
          region: stsInfo.region,
          accessKeyId: stsInfo.accessKeyId,
          accessKeySecret: stsInfo.accessKeySecret,
          bucket: stsInfo.bucket,
          stsToken: stsInfo.secretToken,
          secure: true
        });
        try {
          let dic = await client.multipartUpload(name, file, {
            progress: function(p) {
              //获取进度条的值
              processCallback(p);
            }
          });
          if (dic) {
            let url = dic.res.requestUrls[0];
            if (url.indexOf("?") !== -1) {
              url = url.split("?")[0];
            }
            resolve(url);
          } else {
            reject({
              code: -1,
              name: file.name,
              url: "",
              objectKey: "",
              msg: "上传出错了"
            });
          }
        } catch (e) {
          reject({
            code: -1,
            url: "",
            name: file.name,
            objectKey: "",
            msg: "上传出错了"
          });
        }
      })
      .catch(error => {
        reject({
          code: -1,
          url: "",
          name: file.name,
          objectKey: "",
          msg: "上传出错了"
        });
      });
  });
};
export default {
  uploadFile: CooOss
};
